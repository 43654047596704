.MuiBox-root.schedule-box {
    padding: 0.25rem 1rem;
}
.schedule-date {
    width: 30%;
    max-width: 200px;
}

label.schedule-form-control-label {
    margin-left: 0;
    margin-right: 34px;
}

@media only screen and (max-width: 768px) {
    .MuiBox-root.schedule-box {
        flex-direction: column;
        padding: 1.25rem 1rem 1.5rem;
    }
    .MuiTypography-root.schedule-date {
        max-width: none;
        width: 100%;
        text-align: center;
        font-weight: bold;
    }
    .MuiFormGroup-root.schedule-form-group {
        flex-direction: column;
        width: 100%;
    }
    label.schedule-form-control-label {
        margin-right: 0;
    }
}
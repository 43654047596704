.negative-value{
    color: red
}

.positive-value{
    color: black
}
.balance-footer{
    text-align: end;
    margin-right: 8rem;

}
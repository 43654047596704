.MuiGrid-item.arbitrator-pairing {
    padding: 0.25rem 1rem 0.75rem;
    margin: 0.5rem 0px;
    background-color: rgb(248, 248, 248);
}
.MuiGrid-item.arbitrator-pairing:nth-child(2) {
    background-color: rgb(248, 248, 248);
}

.MuiTypography-root.arbitrator-pairing-school {
    margin: 1rem 0 0;
}

label.arbitrator-pairings-control-label {
    margin-left: 0;
}


@media only screen and (max-width: 768px) {
    .MuiFormGroup-root.arbitrator-pairing-school {
        flex-direction: column;
        width: 100%;
    }
    label.arbitrator-pairings-control-label {
        margin-right: 0;
    }
    .arbitrator-pairings-upload-file {
        margin-top: 0.5rem;
    }

    .label-ranking {
        width: 100%;
    }
}
.header {
    background: #dd1f26;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
}

.header .menu {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.header .menu a.top-link {
  text-decoration: none;
  color:#fff;
  font-size: 0.75rem;
  margin-bottom: 0.95rem;
}

.header .menu a li {
    text-decoration: none;
    color: #000;
}

.branding{
    display: flex;
    align-items: center;
}

.branding p {
    margin: 0 0 0 3rem;
}
  
.branding .logo {
    height: 6rem;
}

  @media only screen and (max-width: 600px) {
    .header {
      padding: 0.5rem 16px;
      margin-bottom: 1rem;
    }

    .header .menu a.top-link {
      margin-bottom: 0;
    }

    .branding p {
      margin-left: 0.5rem;
      font-size: 0.75rem;
    }

    .branding .logo {
      height: 3rem;
    }

  }
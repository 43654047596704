/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web fonts: http://www.fonts.com
*/
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=8e1773db-a9f0-452f-a460-cc8ea640fa18&fontids=1475496,1475502,1475508,1475514,1475520,1475526,1475532,1475538,1475550,1475556,1475562");
@font-face{
    font-family:"Avenir Light";
    src:url("fonts/1475496/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),url("fonts/1475496/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff");
}
@font-face{
    font-family:"Avenir LT W01_35 Light 1475502";
    src:url("fonts/1475502/a59168c1-917d-4de9-a244-0316c057c357.woff2") format("woff2"),url("fonts/1475502/6dc0e7d8-9284-44e1-8f05-984a41daa3a4.woff") format("woff");
}
@font-face{
    font-family:"Avenir Book";
    src:url("fonts/1475508/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),url("fonts/1475508/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff");
}
@font-face{
    font-family:"Avenir LT W01_45 Book O1475514";
    src:url("fonts/1475514/476612d9-282d-4f76-95cd-b4dd31e7ed21.woff2") format("woff2"),url("fonts/1475514/f1ebae2b-5296-4244-8771-5f40e60a564a.woff") format("woff");
}
@font-face{
    font-family:"Avenir LT W01_55 Roman1475520";
    src:url("fonts/1475520/b290e775-e0f9-4980-914b-a4c32a5e3e36.woff2") format("woff2"),url("fonts/1475520/4b978f72-bb48-46c3-909a-2a8cd2f8819c.woff") format("woff");
}
@font-face{
    font-family:"Avenir LT W01_55 Obliqu1475526";
    src:url("fonts/1475526/1a7173fa-062b-49ad-9915-bc57d3bfc1f5.woff2") format("woff2"),url("fonts/1475526/cdda031e-26e9-4269-83d1-5a218caa10db.woff") format("woff");
}
@font-face{
    font-family:"Avenir Medium";
    src:url("fonts/1475532/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"),url("fonts/1475532/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff");
}
@font-face{
    font-family:"Avenir LT W01_65 Medium1475538";
    src:url("fonts/1475538/deb5e718-7abb-4df3-9365-edfa95317090.woff2") format("woff2"),url("fonts/1475538/04801919-17ee-4c6b-8b17-eb1965cb3ed6.woff") format("woff");
}
@font-face{
    font-family:"Avenir LT W01_85 Heavy 1475550";
    src:url("fonts/1475550/3c210c80-960f-4684-850b-25390b4d08af.woff2") format("woff2"),url("fonts/1475550/cb5c71ad-e582-4d00-929c-67fbfaeb1c27.woff") format("woff");
}
@font-face{
    font-family:"Avenir Black";
    src:url("fonts/1475556/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"),url("fonts/1475556/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff");
}
@font-face{
    font-family:"Avenir LT W01_95 Black 1475562";
    src:url("fonts/1475562/a2477e08-09d9-4d4b-97a9-23a1e22cb44c.woff2") format("woff2"),url("fonts/1475562/19d12bba-92b1-43ad-9bab-cd36a4195c2a.woff") format("woff");
}
		                     
body {
  margin: 0;
  font-family: "Avenir Book", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
/*input::-webkit-outer-spin-button,*/
/*input::-webkit-inner-spin-button {*/
/*  -webkit-appearance: none;*/
/*  margin: 0;*/
/*}*/

/*!* Firefox *!*/
/*input[type=number] {*/
/*  -moz-appearance: textfield;*/
/*}*/

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app__body {
  flex: 1;
}

.hover-icon{
  cursor: pointer;
}


.MuiAutocomplete-input,
.MuiAutocomplete-option,
.MuiAutocomplete-root .MuiInputLabel-root {
  font-family: "Avenir Book", Arial, Helvetica, sans-serif !important;
}

button.buttonRedOutline {
  border: 1px #dd1f26 solid;
  background-color: #FFFFFF;
  border-radius: 0;
  box-shadow: none;
  padding: 18px 35px;
  min-width: 260px;
} 

button.buttonRedOutline:hover {
  background-Color: #dd1f26;
  box-shadow: none;
  color: #FFFFFF;
  padding: 18px 35px;
  min-width: 260px;
}

a.color-dark {
  color: #212121;
}

a.color-dark:visited {
  color: #212121;
}

a.color-dark:hover {
  color: #000000;
}

a.color-blue {
  color: rgb(25, 118, 210);
  text-decoration: underline rgba(25, 118, 210, 0.4);
}

a.color-blue:visited {
  color: rgb(25, 118, 210);
  text-decoration: underline rgba(25, 118, 210, 0.4);
}

a.color-blue:hover {
  color: rgb(25, 118, 210);
  text-decoration-color: inherit;
}
.footer {    
    padding: 2rem 24px 0;
}

.footer-row {
    display: flex;
    justify-content: center;
}

.footer-link {
    text-decoration: none; 
    color:rgba(0, 0, 0, 0.87);
    font-size: 0.75rem;
    padding: 1rem;
}

.footer-link:hover {
    text-decoration: underline; 
}



@media only screen and (max-width: 600px) {
    .footer {
        padding: 2rem 16px 0;
    } 

}
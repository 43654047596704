.schedule-time {
    border: 1px solid #aaa !important;
    background: #eee !important;
    color: black !important;
}
.schedule-time--add {
    margin-left: 1rem;
    display: inline-block;
    width: 254px;
}

.schedule-time--field {
    width: 120px;
}
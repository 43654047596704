.draft-score-headers{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'arbitrator claimant respondent';
}

.draft-scores{
    /*display: grid;*/
    /*grid-template-columns: 50px 1fr 1fr 1fr;*/
}

.draft-score-row{
    display: grid;
    grid-template-columns: 50px 1fr 1fr 1fr;
    align-items: start;
}

.draft-score-row:nth-child(even) {background: #efefef;}

.draft-score-row-details{
    display: grid;
    grid-template-columns: 1fr 1fr
}

.score-detail{
    font-weight: bold;
}
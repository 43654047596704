.draft-pairing-group-grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    row-gap: .25rem;
}

.draft-pairing-actions {
    border: 1px solid black;
    padding: 1.25rem;
    display: flex;
    flex-direction: column;
}

.draft-pairing-save {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 1rem;
}

.draft-pairing-group-grid__row{
    display: grid;
    grid-template-columns:  20px 1fr 150px 102px 65px 75px repeat(4,40px) 95px 50px;
    grid-template-rows: auto;
    line-height: 1;
    gap: 0.5rem;
    padding: 0.25rem 0;
    border-bottom: 1px dashed #eee;
}

.draft-pairing-group-grid__row-borderline-fourth {
    border-bottom-color: red;
    position: relative;
}

.draft-pairing-group-grid__row-borderline-fourth::before {
    bottom: -4px;
    background-color: white;
    color: red;
    content: 'quarter borderline (avoid same jurisdiction from crossing)';
    position: absolute;   
    font-size: 0.438rem;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    padding: 0 4px;
}

.draft-pairing-group-grid__row-borderline-eighth {
    border-bottom-color: #F28C28;
    position: relative;
}

.draft-pairing-group-grid__row-borderline-eighth::before {
    bottom: -4px;
    background-color: white;
    color: #F28C28;
    content: 'eighth borderline (preference to avoid same jurisdiction from crossing)';
    position: absolute;   
    font-size: 0.438rem;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    padding: 0 4px;
}

.draft-pairing-group-grid__row-borderline-not-divisible {
    border-bottom-color: red;
    position: relative;
}

.draft-pairing-group-grid__row-borderline-not-divisible::before {
    bottom: -4px;
    background-color: white;
    color: red;
    content: 'extra bolderline (avoid same jurisdiction from crossing)';
    position: absolute;   
    font-size: 0.438rem;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    padding: 0 4px;
}



.draft-pairing-group-grid__row-head{
    margin:0 0 0.5rem;
}

.draft-pairing-day-grid {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    row-gap: .25rem;
}

.draft-pairing-day-grid__row-grid {
    display: grid;
    grid-template-columns: 42px 20px 1fr 150px 42px 20px 1fr 150px;
    grid-template-rows: auto;
    align-items: start;
    line-height: 1;
    border-bottom: 1px dashed #eee;
    gap: 0.5rem;
    padding: 0.25rem 0;
}
.draft-pairing-day-grid__actions{
    display: grid;
    grid-template-columns: 20px 20px;
}
.draft-pairing-day-grid__row-head {
    margin:0 0 0.5rem;
}

.draft-pairing-recap{
    margin-top: 1rem;
}
.draft-pairing-by-jurisdiction-grid__row{
    display: grid;    
    grid-template-columns: 20px 1fr repeat(5,150px) 50px;
    line-height: 1;
    gap: 0.5rem;
    padding: 0.25rem 0;
    border-bottom: 1px dashed #eee;
}

.draft-pairing-by-schedule-grid__row{
    display: grid;
    grid-template-columns: 120px 1fr 1fr;
    gap: 0.5rem;
    border-bottom: 1px dashed #eee;
}

.draft-pairing-by-day-grid__row{
    display: grid;
    grid-template-columns: 20px repeat(5,1fr) 50px;
    line-height: 1;
    gap: 0.5rem;
    padding: 0.25rem 0;
    border-bottom: 1px dashed #eee;
}

.draft-pairing-invalid-text{
    color: red !important;
}

.draft-pairing-warning-text{
    color: orange;
}
